import React from "react"
import theme from "theme"
import { Theme, Image, Text, Button, Box, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Головна | стадiон «Спартак»</title>
        <meta name={"description"} content={"Грай від душі на нашому полі"} />
        <meta property={"og:title"} content={"Головна | стадiон «Спартак»"} />
        <meta
          property={"og:description"}
          content={"Грай від душі на нашому полі"}
        />
        <meta
          property={"og:image"}
          content={"https://cool.novirexstill.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://cool.novirexstill.com/img/football.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://cool.novirexstill.com/img/football.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://cool.novirexstill.com/img/football.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://cool.novirexstill.com/img/football.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://cool.novirexstill.com/img/football.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://cool.novirexstill.com/img/football.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 90px 0"
        background="#6ea263"
        quarkly-title="Product-4"
      >
        <Override slot="SectionContent" align-items="center" />
        <Box
          min-width="100px"
          min-height="100px"
          display="block"
          grid-template-columns="repeat(3, 1fr)"
          grid-template-rows="auto"
          grid-gap="0 35px"
          md-grid-template-columns="1fr"
          md-grid-gap="40px 0"
          margin="0px 0px 0 0px"
          lg-margin="0px 0px 0 0px"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="row"
            align-items="center"
            md-flex-direction="column"
          >
            <Image
              src="https://cool.novirexstill.com/img/1.jpg"
              display="block"
              max-width="100%"
              margin="0px 0px 0 0px"
              height="500px"
              width="50%"
              object-fit="cover"
              sm-height="220px"
              md-width="100%"
              md-margin="0px 0px 50px 0px"
              sm-margin="0px 0px 35px 0px"
            />
            <Box
              min-width="100px"
              min-height="100px"
              padding="0px 80px 0px 80px"
              width="50%"
              lg-padding="0px 50px 0px 50px"
              md-width="100%"
              sm-padding="0px 0 0px 0"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 42px/1.2 --fontFamily-sans"
                text-align="left"
                lg-font="normal 600 36px/1.2 --fontFamily-sans"
              >
                стадiон «Спартак»
              </Text>
              <Text
                margin="0px 0px 50px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                lg-margin="0px 0px 40px 0px"
                sm-margin="0px 0px 30px 0px"
              >
                На футбольній арені стадiону «Спартак» ми обслуговуємо любителів
                футболу, які бажають мати особистий та професійний простір для
                гри. Наша послуга оренди поля забезпечує високоякісне поле в
                дружній та доступній атмосфері. Незалежно від того, чи це
                звичайна гра, чи змагальний матч, ми подбаємо про те, щоб у вас
                було ідеальне тло, щоб насолодитися прекрасною грою.
              </Text>
              <Button
                font="normal 500 18px/1.5 --fontFamily-sans"
                href="/contacts"
                type="link"
                text-decoration-line="initial"
                padding="0 0 0 0"
                background="0"
                color="--primary"
              >
                Зв'яжіться з нами
              </Button>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(2, 1fr)"
          align-items="end"
          grid-gap="0 80px"
          lg-grid-gap="0 50px"
          md-grid-template-columns="1fr"
        >
          <Box
            min-width="100px"
            min-height="100px"
            md-margin="0px 0px 50px 0px"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="normal 600 42px/1.2 --fontFamily-sans"
            >
              Про нас
            </Text>
            <Text
              margin="0px 0px 70px 0px"
              font="--lead"
              lg-margin="0px 0px 40px 0px"
              color="#626970"
            >
              Стадiон «Спартак» присвячена забезпеченню першокласних футбольних
              вражень. Наш об'єкт включає в себе професійно доглянуте трав'яне
              поле, стратегічне освітлення для вечірніх ігор і прості та гнучкі
              варіанти бронювання. Все це створено для того, щоб ви могли грати
              у свою найкращу гру без зайвого клопоту.
            </Text>
            <Image
              src="https://cool.novirexstill.com/img/2.jpg"
              display="block"
              width="100%"
              height="600px"
              object-fit="cover"
              border-radius="15px"
              sm-height="400px"
            />
          </Box>
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 35px 0px"
              border-width="0 0 1px 0"
              border-style="solid"
              border-color="--color-lightD2"
              padding="0px 0px 30px 0px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 500 25px/1.2 --fontFamily-sans"
              >
                Наші Переваги
              </Text>
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
                Найкраще ігрове покриття: На нашому полі використовується
                найсучасніша технологія трав'яного покриття, яка забезпечує
                рівну і швидку поверхню, як на професійних полях.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 35px 0px"
              border-width="0 0 1px 0"
              border-style="solid"
              border-color="--color-lightD2"
              padding="0px 0px 30px 0px"
            >
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
                Вечірнє освітлення: Завдяки нашому високоінтенсивному освітленню
                час гри продовжується навіть уночі, щоб ви могли провести матч у
                будь-який зручний для вас час.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 35px 0px"
              border-width="0 0 1px 0"
              border-style="solid"
              border-color="--color-lightD2"
              padding="0px 0px 30px 0px"
            >
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
                Повна конфіденційність: Під час бронювання ви перебуваєте
                виключно у своєму полі. Ніхто не відволікає, немає спільного
                простору.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              margin="0px 0px 70px 0px"
              lg-margin="0px 0px 40px 0px"
            >
              <Text margin="0px 0px 20px 0px" font="--base" color="#626970">
                Економічно вигідні: Ми пропонуємо одні з найбільш
                конкурентоспроможних тарифів у регіоні, що робить регулярну гру
                доступною для всіх.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
